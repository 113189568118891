<template>
  <div class="usps-qr-selector__wrapper">
    <div>
      <alert-banner
        class="usps-qr-selector__alert"
        type="warning"
        heading="Access to generate your QR code ends today."
        :description="alertDescription"
        :inline="false"
        :is-closable="false"
      />
      <div class="usps-qr-selector__container">
        <div
          class="usps-qr-selector__button"
          @click="handleButtonClick(true)"
        >
          <div class="usps-qr-selector__content-row">
            <div class="usps-qr-selector__icon">
              <img
                class="usps-qr-selector__icon-qr"
                src="/img/icons/barcode.svg"
                alt="Barcode Icon"
              />
            </div>
            <span class="usps-qr-selector__content">
              <base-text type="heading">
                <render-content>
                  {{ $content.moduleUspsQrSelector.qrButtonHeading }}
                </render-content>
              </base-text>
              <base-text
                type="body-2"
                class="usps-qr-selector__subheading"
              >
                <render-content>
                  {{ $content.moduleUspsQrSelector.qrButtonDescription }}
                </render-content>
              </base-text>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="usps-qr-selector__container">
      <div
        class="usps-qr-selector__button"
        @click="handleButtonClick(false)"
      >
        <div class="usps-qr-selector__content-row">
          <div class="usps-qr-selector__icon">
            <img
              class="usps-qr-selector__icon-label"
              src="/img/icons/print.svg"
              alt="Print Icon"
            />
          </div>
          <span class="usps-qr-selector__content">
            <base-text type="heading">
              <render-content>
                {{ $content.moduleUspsQrSelector.labelButtonHeading }}
              </render-content>
            </base-text>
            <base-text type="body-2">
              <render-content>
                {{ $content.moduleUspsQrSelector.labelButtonDescription }}
              </render-content>
            </base-text>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  AlertBanner,
  BaseText,
} from '@loophq/design-system';
import {
  endOfDay,
  intervalToDuration,
} from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

export default {
  components: {
    AlertBanner,
    BaseText,
  },
  emits: ['showQr'],
  data() {
    return {
      timeToEOD: null,
    };
  },
  computed: {
    alertDescription() {
      return `Time remaining: ${this.timeToEOD}`;
    }
  },
  created() {
    this.timeToEOD = this.timeUntilEndOfDayET();
  },
  methods: {
    handleButtonClick(customerWantsQr) {
      this.$emit('showQr', customerWantsQr);
    },
    timeUntilEndOfDayET() {
      let now = zonedTimeToUtc(new Date(), 'America/New_York');

      const end = zonedTimeToUtc(endOfDay(new Date()), 'America/New_York');

      const duration = intervalToDuration({ start: now, end: end });

      return `${duration.hours} hours, ${duration.minutes} minutes`;
    }
  }
};
</script>

<style lang="scss" scoped>
// duplicated value in children
$icon-size: 48px;

.usps-qr-selector {
  &__wrapper {
    margin-top: 0 !important;
  }

  &__button {
    margin: var(--spacing-300) 0;
  }

  &__container {
    border: 1px solid var(--grey-300);
    box-shadow: 0 0 0 1px transparent;
    border-radius: 8px;
    padding: var(--spacing-200) var(--spacing-400);
    margin: var(--spacing-300) 0;
    transition: 0.4s ease border-color, 0.4s ease box-shadow;
    cursor: pointer;

    :deep(.radio-input__label) {
      &::before {
        display: none !important;
      }
    }

    &:hover {
      border: 1px solid var(--grey-900);
      box-shadow: 0 0 0 1px var(--grey-900);
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex: 1;
  }

  &__icon {
    height: $icon-size;
    width: $icon-size;
    margin-right: var(--spacing-300);
    background: white;
    color: var(--heading-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }

  &__option {
    opacity: 1;
    transition: opacity var(--easing-quick);
  }

  &__content-row {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }

  &__subheading {
    margin-top: var(--spacing-100);
  }
}

</style>
