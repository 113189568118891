import WebFont from 'webfontloader';
import { isLight } from '../color';

// Set a global CSS custom property.
// Prefixes all variables with theme-
const setCssVariable = (name, value, prefix = 'theme-') => {
  document.documentElement.style
    .setProperty(`--${prefix}${name}`, value);
};

//function to mimic reducing the opacity of a hex value by returning a new color value
const reduceOpacityHex = (hex, opacity) => {
  const color = hex.replace('#', '');
  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity})`;
};

//function to mimic reducing the opacity of an rgba value by returning a new color value
const reduceOpacityRgb = (color, opacity) => {
  const rgba = color.replace(/^(rgb|rgba)\(/, '').replace(/\)$/, '').replace(/\s/g, '').split(',');
  return `rgba(${rgba[0]},${rgba[1]},${rgba[2]},${opacity})`;
};

//check if the color is hex
const isHex = (color) => {
  return /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(color);
};

const changeFavicon = (img) => {
  let favicon = document.querySelector('link[rel="icon"]');

  if (!favicon) {
    favicon = document.createElement('link');
    favicon.setAttribute('rel', 'icon');
    const head = document.querySelector('head');
    head.appendChild(favicon);
  }
  favicon.setAttribute('type', 'image/png');
  favicon.setAttribute('href', img);
};

const loadFonts = (fonts) => {
  // Nunito Sans is a merchant requested font
  // They specifically want Extra light 200 and italic
  fonts = fonts.map(font => {
    if (font === 'Nunito Sans') {
      return 'Nunito Sans:200italic';
    }
    return font;
  });

  if (fonts.length) {
    WebFont.load({
      google: {
        families: fonts
      }
    });
  }
};

const getCorners = (theme, fallback) => {
  if (theme.globals.corners) {
    return theme.globals.corners;
  }

  return theme.globals.sharpness === 'sharp' ? '0px' : fallback;
};

export default (theme) => {
  // Grab font definitions and filter out any empty ones
  const fonts = [theme.heading.font, theme.body.font].filter(font => font?.length);
  loadFonts(fonts);

  // GLOBALS
  setCssVariable('corners', getCorners(theme, '6px'));
  setCssVariable('text-color', theme.globals.type === 'light' ? 'black' : 'white');
  setCssVariable('text-background-color', theme.globals.type === 'light' ? 'white' : 'black');

  // BACKGROUND
  setCssVariable('background-color-1', theme.background.color1);
  setCssVariable('background-color-2', theme.background.color2);
  setCssVariable('background-image', theme.background.image ? `url(${theme.background.image})` : '');
  setCssVariable('background-size', theme.background.size === 'top' ? '100%' : theme.background.size);
  setCssVariable('background-position', theme.background.position);

  // BRANDING
  setCssVariable('primary-color', theme.branding.primaryColor);
  if (theme.button.background) {
    setCssVariable('button-background-color', theme.button.background);
  } else {
    setCssVariable('button-background-color', theme.branding.primaryColor);
  }
  if (theme.button.color) {
    setCssVariable('button-text-color', theme.button.color);
  } else {
    setCssVariable('button-text-color', isLight(theme.branding.primaryColor) ? 'black' : 'white');
  }
  if (theme.branding.favicon !== '') {
    changeFavicon(theme.branding.favicon);
  }

  // FONTS
  setCssVariable('heading-font', theme.heading.font);
  setCssVariable('heading-weight', theme.heading.weight);
  setCssVariable('heading-case', theme.heading.case);
  if (theme.heading.color) {
    setCssVariable('heading-color', theme.heading.color);
  } else {
    setCssVariable('heading-color', '#202124');
  }

  setCssVariable('body-font', theme.body.font);
  setCssVariable('body-weight', theme.body.weight);
  setCssVariable('body-case', theme.body.case);
  if (theme.body.color) {
    setCssVariable('body-color', theme.body.color);
  } else {
    setCssVariable('body-color', '#747980');
  }

  setCssVariable('button-weight', theme.button.weight);
  setCssVariable('button-case', theme.button.case);

  // Design system
  setCssVariable('corners', getCorners(theme, '8px'), '');
  setCssVariable('primary-color', theme.branding.primaryColor, '');
  if (theme.button.background) {
    setCssVariable('button-background-color', theme.button.background, '');
  } else {
    setCssVariable('button-background-color', theme.branding.primaryColor, '');
  }
  setCssVariable('primary-text-color', isLight(theme.branding.primaryColor) ? 'black' : 'white', '');
  if (theme.button.color) {
    setCssVariable('button-text-color', theme.button.color, '');
  } else {
    setCssVariable('button-text-color', isLight(theme.branding.primaryColor) ? 'black' : 'white', '');
  }
  const secondaryColor = theme.branding.secondaryColor ?? theme.branding.primaryColor;
  setCssVariable('secondary-color', secondaryColor, '');
  setCssVariable('secondary-text-color', isLight(secondaryColor) ? 'black' : 'white', '');
  setCssVariable('button-case', theme.button.case, '');
  setCssVariable('input-offset', '0px', '');
  setCssVariable('heading-font', theme.heading.font, '');
  setCssVariable('body-font', theme.body.font, '');
  setCssVariable('product-background-color', theme.product.background, '');
  setCssVariable('product-image-fit', theme.product.imageFit, '');
  setCssVariable('product-image-position', theme.product.imagePosition, '');
  setCssVariable('outline-color', isHex(theme.branding.primaryColor) ? reduceOpacityHex(theme.branding.primaryColor, 0.3) : reduceOpacityRgb(theme.branding.primaryColor, 0.3));

  if (theme.heading.color) {
    setCssVariable('heading-color', theme.heading.color, '');
  } else {
    setCssVariable('heading-color', '#202124', '');
  }

  if (theme.body.color) {
    setCssVariable('body-color', theme.body.color, '');
  } else {
    setCssVariable('body-color', '#747980', '');
  }

  const logoWidth = theme.branding.logoWidth ? theme.branding.logoWidth : '380px';
  setCssVariable('logo-width', logoWidth);
};
